import React, { useCallback, useEffect, useState } from 'react';
import Chart, {
  Series,
  Aggregation,
  ArgumentAxis,
  Grid,
  Label,
  ValueAxis,
  Margin,
  Legend,
  Tooltip,
} from 'devextreme-react/chart';
import RangeSelector, {
  Size,
  Scale,
  Chart as RsChart,
  ValueAxis as RsValueAxis,
  Series as RsSeries,
  Aggregation as RsAggregation,
  Behavior,
  RangeSelectorTypes,
} from 'devextreme-react/range-selector';

function App() {
  const [visualRange, setVisualRange] = useState({});
  const [time, setTime] = useState<String>("Time");
  const updateVisualRange = useCallback((e: RangeSelectorTypes.ValueChangedEvent) => {
    setVisualRange(e.value);
  }, [setVisualRange]);

  const API_URL = '';
  const get_time = async () => {
    let response = await fetch('time', {method:'GET'});
    if(!response.ok){
        alert("Failed to get Budget");
    } else {
        let data = await response.json();
        setTime(data.time.toString());
    }
}    

const [stock_fullNames, setStock_fullNames] = useState<[[string, string]]>([["", ""]]);
const get_stock_fullNames = async () => {
  let response = await fetch(API_URL+'getTickerFullNames', {method:'GET'});
  if(!response.ok){
      alert("Failed to get Budget");
  } else {
      let data = await response.json();
      var _data: [[string, string]] = [["", ""]];
      for(let i = 0; i < data.tickers_long.length; i++){
        _data.push([data.tickers_short[i], data.tickers_long[i]]);
        console.log(data.tickers_short[i] + " " + data.tickers_long[i])
      }
      setStock_fullNames(_data);
  }
}

const selectRef = React.createRef<HTMLSelectElement>();


const [dataSource, setDataSource] = useState();
const get_stock_data = async () => {
  let ticker = selectRef.current?.value;
  let response = await fetch(API_URL+'getStockData', {method:'POST', headers: {'content-type': 'application/json'}, body: JSON.stringify({ticker: ticker})});
  if(!response.ok){
      // alert("Failed to get Ticker Data");
      console.warn("Failed to get Ticker Data");
  } else {
      let data = await response.json();
      console.log(data);
      setDataSource(data);
  }
}

useEffect(() => {
  get_stock_fullNames();
}, []);

  return (
    <div id="chart-demo">
      <button onClick={get_time}>Get Time</button>
      <p>{time}</p>
      <select ref={selectRef} onChange={get_stock_data}>  
        {stock_fullNames.map((stock_fullName: [string, string], index) => (
          stock_fullName[0] === "" ? <option key={index} value={stock_fullName[0]} selected disabled hidden>Select Stock</option> :
          <option key={index} value={stock_fullName[0]}>
            {stock_fullName[1]}
          </option>
        ))}
      </select>
      <Chart
        id="zoomedChart"
        dataSource={dataSource}
        title="Google Inc. Stock Prices"
      >
        <Series
          type="candlestick"
          openValueField="Open"
          highValueField="High"
          lowValueField="Low"
          closeValueField="Close"
          argumentField="Date"
        >
          <Aggregation enabled={true} />
        </Series>
        <ArgumentAxis
          visualRange={visualRange}
          valueMarginsEnabled={false}
          argumentType="datetime"
        >
          <Grid visible={true} />
          <Label visible={false} />
        </ArgumentAxis>
        <ValueAxis valueType="numeric" />
        <Margin right={10} />
        <Legend visible={false} />
        <Tooltip enabled={true} />
      </Chart>
      <RangeSelector
        dataSource={dataSource}
        onValueChanged={updateVisualRange}
      >
        <Size height={120} />
        <RsChart>
          <RsValueAxis valueType="numeric" />
          <RsSeries
            type="line"
            valueField="Open"
            argumentField="Date"
          >
            <RsAggregation enabled={true} />
          </RsSeries>
        </RsChart>
        <Scale
          placeholderHeight={20}
          minorTickInterval="day"
          tickInterval="month"
          valueType="datetime"
          aggregationInterval="week"
        />
        <Behavior
          snapToTicks={false}
          valueChangeMode="onHandleMove"
        />
      </RangeSelector>
    </div>
  );
}

export default App;
